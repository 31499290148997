import React, { useEffect } from "react";
import "./App.css";
import onboard from "./assets/onboard_logo.png";
import ts from "./assets/ts_logo.png";
import mvsi from "./assets/mvsi-web-logo.png";
import ServiceNotAvailable from "./Components/service-not-available";

const GATEKEEPER_SIGN_IN_URL =
  "https://mvsi-team.myfreshworks.com/sp/OIDC/355921604812458591/login?redirect_uri=https://mvsi.freshdesk.com/freshid/customer_authorize_callback?hd=desk.mvsi.com&client_id=451980330120475313";
const ONBOARD_SIGN_IN_URL =
  "https://mvsi-team.myfreshworks.com/sp/OIDC/358489123378695279/login?redirect_uri=https://mvsi.freshdesk.com/freshid/customer_authorize_callback?hd=desk.mvsi.com&client_id=451980330120475313";

function App() {
  const { pathname } = window.location;

  useEffect(() => {
    if (pathname === "/onboard") {
      window.location.href = ONBOARD_SIGN_IN_URL;
    }
    if (pathname === "/gatekeeper") {
      window.location.href = GATEKEEPER_SIGN_IN_URL;
    }
  }, [pathname]);

  if (pathname === "/service-not-available") {
    return <ServiceNotAvailable />;
  }

  return (
    <div className="App">
      <style>
        {`@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap');`}
      </style>
      <div className="App-Header">
        <img alt="mvsi logo" src={mvsi} className="mvsi-logo" />
        <h1 className="welcome-header">Welcome to the MVSI Support Centre</h1>
      </div>
      <div className="App-Container">
        <div className="overlay-contents">
          <h4 className="login-existing">
            Please login with your existing credentials below.
          </h4>
          <div className="onboard-ts">
            <div className="logo-block">
              <a href={`${ONBOARD_SIGN_IN_URL}`}>
                <div className="onboard-logo">
                  <img src={onboard} alt="Onboard" />
                  <h1>onboard</h1>
                </div>
              </a>
            </div>
            <div className="logo-block">
              <a href={`${GATEKEEPER_SIGN_IN_URL}`}>
                <div className="ts-logo">
                  <img src={ts} alt="Talent Screen" />
                </div>
              </a>
            </div>
          </div>
          <h2 className="help-desk">Help Desk</h2>
        </div>
        {/* <div className="background-image-clipper">
          <div className="background-image-container" />
        </div> */}
      </div>
    </div>
  );
}

export default App;
